<template>
  <div class="flex h-full items-center tabular-nums" :class="params.justifyStart ? 'justify-start' : 'justify-end'">
    {{ btcValue }}
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { roundedBtc } from '@/composeables/filters';

const props = defineProps({
  params: {
    type: Object,
    default: undefined
  }
});

const decimals = computed(() => props.params.decimals || 0);
const btcValue = computed(() => roundedBtc(Number(props.params.value || ''), decimals.value));
</script>
